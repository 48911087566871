<template>
    <div>
        <div >
            <base-header class="pb-6">
                <div class="row align-items-center py-4">
                    <div class="col-lg-6 col-7">
                        <h6 class="h2 text-white d-inline-block mb-0"></h6>
                    </div>      
                </div>
            </base-header>
            <div class="container-fluid mt--6">
                <div class="card mb-4">
                    <div class="card-header mt--4 mb--4">
                        <div class="row">
                            <div class="col">
                                <h3 class="mb-0">{{ tt('bom_characteristic') }}</h3>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <input type="text" class="form-control form-control-sm text-center border-radius-20" v-model="table.search" :placeholder="tt('search')" v-on:keyup="filter"/>                            
                                </div>
                            </div>
                            <div class="col text-right">
                                <base-button size="sm" type="default" @click="create">{{ tt('add_new') }}</base-button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <el-table height="350px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="table.data" v-if="!onLoad">
                            <el-table-column :label="tt('name')" :prop="tt('name')" min-width="250px" sortable>
                                <template v-slot="{row}">
                                    {{row.bom_characteristic_name}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('single_multiple')" :prop="tt('single_multiple')" min-width="180px" sortable>
                                <template v-slot="{row}">
                                    <span v-if="row.single_or_multiple == 1">SINGLE</span>
                                    <span v-else-if="row.single_or_multiple == 2">MULTIPLE</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="action" width="100">
                                <template v-slot="{row}">
                                    <el-dropdown trigger="click" class="dropdown" @command="handleTableAction" :hide-on-click="true">
                                        <span class="btn btn-sm btn-icon-only text-light">
                                            <i class="fas fa-ellipsis-v mt-2"></i>
                                        </span>
                                        <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                                            <el-dropdown-item :command="{action:'edit', bom_characteristic_code:row.bom_characteristic_code, name:row.bom_characteristic_name, single_or_multiple:row.single_or_multiple}">{{ tt('edit') }}</el-dropdown-item>
                                            <el-dropdown-item :command="{action:'remove', bom_characteristic_code:row.bom_characteristic_code}">{{ tt('delete') }}</el-dropdown-item>
                                        </el-dropdown-menu>
                                    </el-dropdown>
                                </template>
                            </el-table-column>
                        </el-table>
                        <page-loading v-else/>
                    </div>      
                    <div class="card-footer pb-0 ">   
                        <span class="float-left">
                            <base-pagination :page-count="table.page.last_page" v-model="table.page.current_page" @click.native="changePage(table.page.current_page)" size="sm"></base-pagination>
                        </span>                             
                        <span class="float-right">
                            {{ tt('page_info', {size: (table.page.current_page - 1) * table.page.per_page + table.data.length, total: table.total}) }}
                        </span>
                    </div>
                </div>
            </div>

            <validation-observer v-slot="{invalid}">
                <modal :show.sync="form.show">
                    <template slot="header">
                        <h5 class="modal-title">{{form.title}}</h5>
                    </template>
                    <div>
                        <base-alert type="danger" v-if="errorMessage">
                            <p><strong>{{ errorMessage.message }}</strong></p>
                            <div v-if="errorMessage.data.length != 0">
                                <span v-for="message in errorMessage.data">
                                    {{ message[0] }}<br>
                                </span>
                            </div>
                        </base-alert>
                        
                        <label class="form-control-label">{{ tt('name') }} <span class="text-danger">*</span></label>
                        <base-input :name="tt('name')" :placeholder="tt('name')" v-model="bomCharacteristicMaster.bom_characteristic_name" rules="required" @input="bomCharacteristicMaster.bom_characteristic_name = bomCharacteristicMaster.bom_characteristic_name.toUpperCase()"></base-input>

                        <label class="form-control-label">{{ tt('single_multiple') }} <span class="text-danger">*</span></label>
                        <base-input :name="tt('single_multiple')" rules="required">
                            <el-select class="select-danger" v-model="bomCharacteristicMaster.single_or_multiple" :placeholder="tt('choose_single_multiple')">
                                <el-option class="select-danger" value="1" label="Single" key="Single"></el-option>
                                <el-option class="select-danger" value="2" label="Multiple" key="Multiple"></el-option>
                            </el-select>
                        </base-input>
                    </div>

                    <template slot="footer">
                        <base-button type="secondary" @click="form.show = false">{{ tt('close') }}</base-button>
                        <base-button type="primary" v-on:click="save" :disabled="btnSave.onLoading || invalid">
                            <span v-if="btnSave.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                            <span v-else>
                                <span v-if="form.add">{{ tt('add') }}</span>
                                <span v-else>{{ tt('edit') }}</span>
                            </span>
                        </base-button>
                    </template>

                </modal>
            </validation-observer>
        </div>
    </div>
</template>
<script>
    import {mapState} from 'vuex'
    import Api from '@/helpers/api';
    import characteristicMaster from '@/services/dictionary/characteristicMaster.service';
    import bomCharacteristicMaster from '@/services/bom/bomCharacteristicMaster.service';

    export default {        
        data() {
            return {      
                onLoad: true,      
                loadTimeout: null,  
                btnSave: {
                    onLoading: false
                },            
                form: {
                    title: this.tt('add_characteristic_master'),
                    show: false
                },
                table: {
                    search: '',                    
                    total: 0,
                    data: [],
                    page: {
                        current_page: 1,
                        per_page: 100,
                    },
                },     
                errorMessage: null,   
                // characteristicMaster: {},
                bomCharacteristicMaster: {},
            }
        },
        computed: {
            ...mapState({
                authUserPermission :state => state.auth.userPermission
            }),
        },
        mounted() {
            this.get()
        },
        methods: {
            get() { 
                let context = this;               
                Api(context, bomCharacteristicMaster.get(this.table.page.current_page, {search: this.table.search})).onSuccess(function(response) {    
                    context.table.total = response.data.data.data.total;
                    context.table.data = response.data.data.data.data;
                    context.table.page  = response.data.data.data;          
                }).onError(function(error) {                    
                    context.table.data = [];
                    context.table.total = 0;
                }).onFinish(() =>{
                    context.onLoad = false;
                }).call()
            },
            filter() {
                this.onLoad = true;
                this.table.page.current_page = 1;
                clearTimeout(this.loadTimeout);
                this.loadTimeout = setTimeout(() => {
                    this.get()
                }, 100);
            },
            handleTableAction(command) {
                switch (command.action) {
                    case 'edit':
                            this.edit(command.bom_characteristic_code, command.name, command.single_or_multiple);
                        break;
                    case 'remove':
                            this.remove(command.bom_characteristic_code);
                        break;
                    default:
                        break;
                }
            },
            create() {
                this.errorMessage = null;
                this.form.add = true;
                this.form.title = this.tt('add_characteristic_master');
                this.form.show = true;
                this.bomCharacteristicMaster = {
                    'bom_characteristic_name': '',
                    'single_or_multiple': '',
                };
            },            
            edit(bom_characteristic_code,name,single_or_multiple) {
                this.errorMessage = null;
                this.form.add = false;
                this.form.title = this.tt('edit_characteristic_master');
                this.form.show = true;
                this.bomCharacteristicMaster = {
                    'bom_characteristic_code':bom_characteristic_code,
                    'bom_characteristic_name': name,
                    'single_or_multiple': single_or_multiple,
                };      
            },
            save() {                      
                this.btnSave.onLoading = true;
                let api = null;
                let context = this;

                // context.get();
                if (this.form.add) {
                    api = Api(context, bomCharacteristicMaster.create({'bom_characteristic_name':this.bomCharacteristicMaster.bom_characteristic_name, 'single_or_multiple':this.bomCharacteristicMaster.single_or_multiple}));
                } else {{
                    api = Api(context, bomCharacteristicMaster.update(this.bomCharacteristicMaster.bom_characteristic_code, this.bomCharacteristicMaster));
                }}
                api.onSuccess(function(response) {
                    context.onLoad = true;
                    context.$notify({
                        message: response.data.message,                  
                        type: 'success',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });
                    context.get();  
                }).onError((error) =>{
                    context.$notify({
                        message: error.response.data.message,                  
                        type: 'danger',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });
                }).onFinish(function() {
                    context.btnSave.onLoading = false; 
                    context.form.show = false;
                })
                .call();
            },
            remove(code) {
                this.confirmDialog(this.tt('confirm_delete')).then((result) => {
                    let context = this;
                    if (result.value) {
                        Api(context, bomCharacteristicMaster.delete(code))
                        .onSuccess(function(response) {
                            context.$notify({
                                message: response.data.message,
                                type: 'success',
                                verticalAlign: 'bottom', 
                                horizontalAlign: 'left'
                            });
                            context.get();
                        }).onError((error) =>{
                            context.$notify({
                                message: error.response.data.message,
                                type: 'danger',
                                verticalAlign: 'bottom', 
                                horizontalAlign: 'left'
                            });
                        }).call();
                    }
                })
            },
            changePage(page) {
                let context = this;
                context.onLoad = true;

                clearTimeout(this.loadTimeout);
                this.loadTimeout = setTimeout(() => {
                    this.get()
                }, 100);
            },
        }   
    };
</script>
<style></style>
